exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-captura-js": () => import("./../../../src/pages/captura.js" /* webpackChunkName: "component---src-pages-captura-js" */),
  "component---src-pages-conquistas-js": () => import("./../../../src/pages/conquistas.js" /* webpackChunkName: "component---src-pages-conquistas-js" */),
  "component---src-pages-cursos-js": () => import("./../../../src/pages/cursos.js" /* webpackChunkName: "component---src-pages-cursos-js" */),
  "component---src-pages-edicao-2021-js": () => import("./../../../src/pages/edicao2021.js" /* webpackChunkName: "component---src-pages-edicao-2021-js" */),
  "component---src-pages-edicoes-js": () => import("./../../../src/pages/edicoes.js" /* webpackChunkName: "component---src-pages-edicoes-js" */),
  "component---src-pages-empresa-js": () => import("./../../../src/pages/empresa.js" /* webpackChunkName: "component---src-pages-empresa-js" */),
  "component---src-pages-etapa-js": () => import("./../../../src/pages/etapa.js" /* webpackChunkName: "component---src-pages-etapa-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-gamejam-js": () => import("./../../../src/pages/gamejam.js" /* webpackChunkName: "component---src-pages-gamejam-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-numeros-js": () => import("./../../../src/pages/numeros.js" /* webpackChunkName: "component---src-pages-numeros-js" */),
  "component---src-pages-organizadores-js": () => import("./../../../src/pages/organizadores.js" /* webpackChunkName: "component---src-pages-organizadores-js" */),
  "component---src-pages-publishers-js": () => import("./../../../src/pages/publishers.js" /* webpackChunkName: "component---src-pages-publishers-js" */),
  "component---src-pages-videos-js": () => import("./../../../src/pages/videos.js" /* webpackChunkName: "component---src-pages-videos-js" */)
}

